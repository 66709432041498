import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrevNext from "../components/prevNext"

import Logo from "../../content/assets/logo_ogp.webp"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { prev, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.thumbnail.childImageSharp.resize.src || Logo}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <Img
                className="kg-image"
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                alt={post.frontmatter.title}
                loading="eager"
                durationFadeIn={100}
              />
            </div>
          )}

          {post.frontmatter.client && (
                <p className="post-content-meta">
                    <b>Client</b>: {post.frontmatter.client}
                    </p>)}


          {post.frontmatter.skills && (
                <p className="post-content-meta">
                    <b>Skills</b>: {post.frontmatter.skills.join(', ')}
                    </p>)}

          {post.frontmatter.length && (
                <p className="post-content-meta">
                    <b>Length</b>: {post.frontmatter.length}
                    </p>)}

          {post.frontmatter.role && (
                <p className="post-content-meta">
                    <b>Role</b>: {post.frontmatter.role.join(', ')}
                    </p>)}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <PrevNext prev={prev} next={next} />

          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        client
        skills
        role
        length
        thumbnail {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
